import React from 'react';
import coreQuantumIcon from './assets/icons/corequantum.png';
import aiMlIcon from './assets/icons/aiml.png';
import webDevIcon from './assets/icons/webdev.png';
import corporateIcon from './assets/icons/corporate.png';
import editorialIcon from './assets/icons/editorial.png';
import creativesIcon from './assets/icons/creatives.png';



const domains = [
  {
    title: "Core Quantum",
    icon: coreQuantumIcon,
    description: "This is where we tackle the heart of quantum computing, diving deep into quantum mechanics, algorithms, and hardware. Our Core team is constantly exploring new dimensions of computing, breaking down the complexities of quantum science to create groundbreaking solutions."
  },  {
    title: "Corporate",
    icon: corporateIcon,
    description: "Our Corporate team is the backbone of our external relationships. They are the strategists who connect us with the industry, securing sponsorships, partnerships, and opportunities that propel the club forward. They ensure that we are not only advancing in technology but also building a sustainable future for the club."
  },

  {
    title: "Web Development",
    icon: webDevIcon,
    description: "Behind every great initiative is an exceptional digital presence. Our Web Development team designs sleek, intuitive platforms that showcase our projects and allow others to engage with the quantum computing universe. We build the bridge between innovation and accessibility."
  },

  {
    title: "Editorial",
    icon: editorialIcon,
    description: "Words have power, and our Editorial team wields that power masterfully. Whether through thought-provoking blog posts, insightful articles, or engaging social media content, they craft the narrative that reflects the club's voice and communicates our mission to a wider audience."
  },
  {
    title: "AI/ML",
    icon: aiMlIcon,
    description: "We blend the power of quantum computing with artificial intelligence and machine learning, exploring how these two cutting-edge technologies can intersect to revolutionize industries. Our AI/ML team is at the forefront of building smarter, faster, and more efficient systems."
  },
  {
    title: "Creatives",
    icon: creativesIcon,
    description: "Visionaries at heart, our Creatives team brings the essence of the club to life through stunning visuals, captivating designs, and artistic storytelling. From posters to video content, they shape how we present ourselves with innovation and flair."
  },
];

const Domains = () => {
  return (
    <div
      className="min-h-screen bg-cover bg-center py-10">
      <h2 className="text-white text-4xl sm:text-3xl text-center font-bold font-abel mb-16">Domains</h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-20 px-5 sm:px-20"> {/*  gap for mobile */}
        {domains.map((domain, index) => (
          <div
            key={index}
            className="relative bg-black bg-opacity-40 p-8 rounded-xl shadow-lg hover:bg-green-900 transition-all duration-300 w-full h-auto flex flex-col justify-center items-center"
            style={{ boxShadow: "0 0 10px 2px rgba(34, 197, 94, 0.3)" }} // Subtle glowing border
          >
            {/* Icon Partially Above Card */}
            <div className="absolute -top-10 w-28 h-28 bg-black flex justify-center items-center rounded-full border-4 border-gray-900 shadow-lg">
              <img
                src={domain.icon}
                alt={domain.title}
                className="h-20 w-20"
              />
            </div>
            {/* Title and Description */}
            <div className="mt-12 text-center px-8">
              <h3 className="text-2xl sm:text-xl text-white font-semibold mb-4">{domain.title}</h3>
              <p className="text-gray-200 text-sm  leading-relaxed text-center">{domain.description}</p> 
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Domains;