import React from 'react';
import Quantumlogo from '../Quantumlogo/Quantumlogo';
import './Home.css'; // Import the CSS file
import AboutUs from './AboutUs';
import Domains from '../Domains/Domains';

const Home = () => {
  return (
    <>
    <div className="home-container">
      <div className="content">
        <div className="logo-container">
          <Quantumlogo />
        </div>
        <div className="title">
          <h1>
            SRMIST QUANTUM COMPUTING CLUB
          </h1>
        </div>


      </div>
    </div>
    <AboutUs/>
    <div classname="domain-container">
      <Domains/>
      </div>
</>
  );
};

export default Home;