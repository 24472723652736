import React, { useState } from 'react';
import './Team.css';

function Team() {
  // Define members for core, heads, and domain members
  const coreMembers = [
    { name: 'Tanishq Kumar', description: 'President', image: '/profile_images/Tanishq Kumar.jpg' },
    { name: 'Shashanka shekhar sharma', description: 'Vice President', image: '/profile_images/Shashanka shekhar sharma.jpeg' },
    { name: 'Lakshya Malik  ', description: 'Vice President', image: '/profile_images/Lakshya Malik.jpg' },
  ];

  const headMembers = [
    { name: 'Bhavesh Rai', description: 'Tech Director', image: '/profile_images/Bhavesh Rai - Rai Bhavesh.jpg' },
    { name: 'Modhwadia Amit Rambhai ', description: 'Tech Director', image: 'profile_images/IMG_20230228_184410 - Amit Modhwadia.jpg' },
    { name: 'Hrishikesh Sarma', description: 'Non-Tech Director', image: 'profile_images/Hrishi - Hrishi Sarma.jpg' },
    { name: 'Aditya Singhi', description: 'Non-Tech Director', image: 'profile_images/IMG_9026 - Aditya singhi.jpeg' },
  ];

  const domainMembers = {
    "Core Quantum": [
      { name: 'Karan Y Islur', description: 'Lead', image: 'profile_images/WhatsApp Image 2024-10-03 at 4.50.55 PM.jpeg' },
      { name: 'Priyanshi Singh ', description: 'Lead', image: 'profile_images/Screenshot_20240923_205139 - Iyanshi.jpg' },
      { name: 'Nishant Sheoran', description: 'Associate', image: 'profile_images/new - NISHANT SHEORAN (RA2211003011324).jpg' },
      { name: 'Pratyush Agarwal ', description: 'Member', image: 'profile_images/Screenshot_2023-12-13-13-37-21-74_965bbf4d18d205f782c6b8409c5773a4~7 - SUNITA AGARWAL.jpg' },
      { name: 'Sanket Dhumal', description: 'Member', image: 'profile_images/Photo - SANKET DHUMAL (RA2211026010020).jpg' },

    ],
    "AI/ML": [
        { name: 'Achintya Gupta ', description: 'Lead', image: 'profile_images/WhatsApp Image 2024-10-03 at 4.54.00 PM.jpeg' },
        { name: 'Ponnuri Aniruddha', description: 'Lead', image: 'profile_images/Ponnuri Aniruddha - Shantha Kumari.jpg' },
        { name: 'Aaryan Anand', description: 'Lead', image: 'profile_images/Screenshot_2024_0424_230841 - Aaryan Anand.jpg' },
        { name: 'Bhavesh Rai', description: 'Lead', image: 'profile_images/Bhavesh Rai - Rai Bhavesh.jpg' },
        { name: 'Kadasani V Ruthvik Reddy', description: 'Associate', image: 'profile_images/Screenshot 2024-10-03 164424.png' }
    ],
    "Web Dev": [
      { name: 'Aaditya Pratap Singh', description: 'Lead', image: 'profile_images/WhatsApp Image 2024-10-02 at 3.33.37 PM.jpeg' },
      { name: 'Tathagata Bhattacherjee', description: 'Lead', image: 'profile_images/Photo - Tathagata Bhattacherjee.jpg' },
      { name: 'Pulkit Shringi', description: 'Associate', image: 'profile_images/colorful id - PULKIT SHRINGI (RA2111003010596).jpg' },
      { name: 'Akanksha Mishra', description: 'Associate', image: 'profile_images/WhatsApp Image 2024-10-03 at 4.59.48 PM.jpeg' },
      { name: 'Vanshika Sharma', description: 'Associate', image: 'profile_images/WhatsApp Image 2024-10-03 at 4.03.23 PM.jpeg' },
      { name: 'Rahul Sharma ', description: 'Member', image: 'profile_images/IMG-20240817-WA0051 - Rahul Sharma.jpg' },
      { name: 'Anjali Choudhary  ', description: 'Member', image: 'profile_images/Screenshot_20240923_220307_Gallery - ANJALI CHOUDHARY (RA2211003011553).jpg' },
      { name: 'Rohit Govind', description: 'Member', image: 'profile_images/Screenshot 2024-03-20 222727 - ROHIT G.png' }

    ],
    "Corporate": [
      { name: 'Aditya Singhi ', description: 'Lead', image: 'profile_images/IMG_9026 - Aditya singhi.jpeg' },
      { name: 'Ashneet Kaur Sukhija', description: 'Lead', image: 'profile_images/IMG_0153 - Ashneet Sukhija.jpeg' },
      { name: 'Rahul Sharma', description: 'Associate', image: 'profile_images/Rahulsharma(594).jpg' },
      { name: 'Karan Raj Sharma', description: 'Associate', image: 'profile_images/IMG_2905 - Karan Raj Sharma.jpeg' },
      { name: 'Prishu Raj Singh', description: 'Associate', image: 'profile_images/IMG_1694 - Prishu Raj Singh.jpeg' },

    ],
    "Creatives": [
      { name: 'Hrishikesh Sarma', description: 'Lead', image: 'profile_images/Hrishi - Hrishi Sarma.jpg' },
      { name: 'Arnab Kumar Mal', description: 'Lead', image: 'profile_images/Arnab Kumar Mal,jpg-photoaidcom-cropped - Arnab Mal.jpeg' },
      { name: 'Priyam Bharti ', description: 'Associate', image: 'profile_images/IMG-20240916-WA0360 - PRIYAM BHARTI (RA2311003010351).jpg' },
      { name: 'Aaditya Nigam  ', description: 'Associate', image: 'profile_images/IMG_6561 a - Aaditya Nigam.jpg' }

    ],
    "Editorial": [
      { name: 'Naman Srivastava', description: 'Lead', image: 'profile_images/Naman Srivastava - NAMAN SRIVASTAVA (RA2211003011232).jpg' },
      { name: 'Sumedha Parashar', description: 'Associate', image: 'profile_images/porfile - Sumedha Parashar.jpg' },
      { name: 'Tanish Bhardwaj', description: 'Associate', image: 'profile_images/84C7B675-D48F-42E9-A5F3-ED2B38DF77E8 - Tanish Bhardwaj.jpeg' },
      { name: 'Rohith Pilli', description: 'Associate', image: 'profile_images/photo - ROHITH PILLI (RA2211004010508).jpg' }
 
    ]
  };

  const [selectedDomain, setSelectedDomain] = useState("Core Quantum");

  return (
    <div className="App">
      <h1>Meet The Team</h1>
      
      {/* Core Section */}
      <div className="core-section">
        <h2>Core</h2>
        <div className="core-container">
          {coreMembers.map((member, index) => (
            <div key={index} className="core-member">
              <img src={member.image} alt={member.name} className="core-image" />
              <h3>{member.name}</h3>
              <p>{member.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Heads Section */}
      <div className="heads-section">
        <h2>SQCC Board</h2>
        <div className="heads-container">
          {headMembers.map((member, index) => (
            <div key={index} className="head-member">
              <img src={member.image} alt={member.name} className="head-image" />
              <h3>{member.name}</h3>
              <p>{member.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Domain Members Section */}
      <div className="domain-section">
        <div className="domain-buttons">
          {Object.keys(domainMembers).map(domain => (
            <button
              key={domain}
              onClick={() => setSelectedDomain(domain)}
              className={selectedDomain === domain ? "active" : ""}
            >
              {domain}
            </button>
          ))}
        </div>
        <div className="domain-container">
          {domainMembers[selectedDomain].map((member, index) => (
            <div key={index} className="domain-member">
              <img src={member.image} alt={member.name} className="domain-image" />
              <h3>{member.name}</h3>
              <p>{member.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Team;
