import React from "react";
import "./Achievements.css";

const Achievements = () => {
  return (
    <div className="achievements-container">
      <h1>Our Alumnas Placed</h1>

      <div className="achievement a1">
        {/* Replace the placeholder with an actual image */}
        <img src="profile_images/WhatsApp Image 2024-10-03 at 5.44.28 PM.jpeg" alt="Abhishek Biswas" className="achievement-image" />
        <div className="achievement-text">
          <h2>Abhishek Biswas (BNY Mellon)</h2>
          <p>
            BNY is a global financial services company overseeing nearly $50
            trillion — managing it, moving it, and keeping it safe.
          </p>
        </div>
      </div>

      <div className="achievement a2">
        <img src="profile_images/WhatsApp Image 2024-10-03 at 5.44.29 PM.jpeg" alt="Saaru Darshini" className="achievement-image" />
        <div className="achievement-text">
          <h2>Saaru Darshini (CDAC)</h2>
          <p>
            C-DAC with its focus in Advanced Computing is uniquely positioned to establish dependable and secure Exascale Ecosystem.
          </p>
        </div>
      </div>

      <div className="achievement a3">
        <img src="profile_images/WhatsApp Image 2024-10-03 at 4.50.55 PM.jpeg" alt="Karan Y Islur" className="achievement-image" />
        <div className="achievement-text">
          <h2>Karan Y Islur (BQP)</h2>
          <p>
            BosonQ Psi offers quantum-powered cloud simulations to accelerate CAE processes, optimize design capabilities.
          </p>
        </div>
      </div>

      <div className="achievement a4">
        <img src="profile_images/Tanishq Kumar.jpg" alt="Tanishq Kumar" className="achievement-image" />
        <div className="achievement-text">
          <h2>Tanishq Kumar (BQP)</h2>
          <p>
            BosonQ Psi offers quantum-powered cloud simulations to accelerate CAE processes, optimize design capabilities.
          </p>
        </div>
      </div>

      <div className="achievement a5">
        <img src="profile_images/WhatsApp Image 2024-10-03 at 5.45.00 PM.jpeg" alt="Vikram Aditya" className="achievement-image" />
        <div className="achievement-text">
          <h2>Vikram Aditya (CDAC)</h2>
          <p>
            C-DAC with its focus in Advanced Computing is uniquely positioned to establish dependable and secure Exascale Ecosystem.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Achievements;
