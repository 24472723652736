import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import Event from './Components/Event/Event';
import Team from './Components/Team/Team';
import Achievements from './Components/Achievements/Achievement';
import Gallery from './Components/Gallery/Gallery';
import Contact from './Components/Contact/contact';
import Footer from './Components/Footer/Footer';
import ScrollToTop from './Components/ScrollToTop'; 

const App = () => {
  return (
    <Router>
      <ScrollToTop /> {/* To go to the top of the page everytime we go to any other page through footer links */}
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Team" element={<Team />} />
        <Route path="/Event" element={<Event />} />
        <Route path="/Achievements" element={<Achievements />} />
        <Route path="/Gallery" element={<Gallery />} />
        <Route path="/Contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
