import React, { useState } from "react";
import "./contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const response = await fetch("YOUR_WEB_APP_URL", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: formData.name,
                email: formData.email,
                message: formData.message
            }),
            mode: 'cors'
        });

        const result = await response.json();
        if (result.result === "success") {
            alert("Form submitted successfully!");
            setFormData({ name: '', email: '', message: '' });
        } else {
            alert("Something went wrong. Please try again.");
        }
    } catch (error) {
        console.error('Error:', error);
        alert("Failed to submit the form. Please check your internet connection or try again later.");
    }
  };

  return (
    <div className="contact-background">
      <div className="contact-form-container">
        <h2 className="contact-title">Get In Touch With Us</h2>
        <form className="contact-form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Full name"
            required
            className="contact-input"
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email Address"
            required
            className="contact-input"
          />
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Message"
            required
            className="contact-textarea"
          />
          <button type="submit" className="contact-submit-button">
            SUBMIT
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
