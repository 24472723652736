import React, { Suspense, useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';

const Model = () => {
  const modelRef = useRef(); 

  
  const { scene } = useGLTF('/assets/SQCC.glb');

 
  useFrame(() => {
    if (modelRef.current) {
      modelRef.current.rotation.y += 0.01; 
    }
  });

 
  return <primitive ref={modelRef} object={scene} scale={[2.5, 2.5, 2.5]} position={[0, 0, 0]} />;
};

const QuantumLogo = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Canvas style={{ height: '300px' }}>
        <ambientLight intensity={0.5} />
        <directionalLight position={[10, 10, 5]} />
        <Model />
        <OrbitControls enableZoom={false} />
      </Canvas>
    </Suspense>
  );
};

export default QuantumLogo;
