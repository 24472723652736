import React from 'react';
import './event.css';

const event1Image = '/assets/Screenshot 2024-10-02 155338.png'; 
const event2Image = '/assets/Screenshot 2024-10-03 144519.png';   

const Event = () => {
    return (
        <div className="event-background" >
            <h2 className="event-title">EVENTS</h2>
            <div className="event-container">
                {/* quantathon1.0 event card*/}
                <div className="event-wrapper">
                    <div className="event-card">
                        <img src={event1Image} alt="Event" className="event-card-image" />
                        <div className="event-details">
                            <h3 className="event-name">Quantathon 1.O</h3>
                            <p className="event-date">18th & 19th August 2023</p>
                        </div>
                    </div>
                    <button className="learn-more-button" onClick={() => window.open('https://quantathon.vercel.app/', '_blank')}>
                        Learn More
                    </button>

                </div>
                <div className="event-wrapper">
                    <div className="event-card">
                        <img src={event2Image} alt="Event" className="event-card-image" />
                        <div className="event-details">
                            <h3 className="event-name">Quantathon 2.O</h3>
                            <p className="event-date">14th-18th October 2024</p>
                        </div>
                    </div>
                    <button className="learn-more-button" onClick={() => window.open('https://quanthathon200.vercel.app/', '_blank')}>
                        Learn More
                    </button>
                </div>
                
            </div>
        </div>
    );
}

export default Event;
