import React, { useEffect, useRef, useState } from 'react';
import './AboutUs.css';

function App() {
  const [isHovered, setIsHovered] = useState(false); 

  const newsletters = [
    { image: '/assets/newsletter1.png' },
    { image: '/assets/newsletter2.png' },
    { image: '/assets/newsletter3.png' },
    { image: '/assets/newsletter4.png' },
    { image: '/assets/newsletter5.png' },
    { image: '/assets/newsletter6.png' }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % newsletters.length);
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + newsletters.length) % newsletters.length);
  };

  useEffect(() => {
    if (!isHovered) {
      const interval = setInterval(goToNext, 2000); 
      return () => clearInterval(interval); 
    }
  }, [isHovered]);

  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const newsletterRef = useRef(null);

  const handleNavigation = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="app">

      <section ref={aboutRef} className="about">
        <h1>About Us</h1>
        <div className="about-content">
          <p>
            The Quantum Computing Club SRMIST is a trailblazing community of innovators, tech enthusiasts, and creative minds united by a passion for exploring the fascinating frontier of quantum computing. We are driven by curiosity and the desire to push the boundaries of what’s possible in the quantum realm.
            <br /><br />
            Our club is a hub of talent, where individuals from various domains collaborate to turn ambitious ideas into reality. With a strong foundation in both technical and non-technical fields, we foster an environment that encourages innovation, learning, and creative expression.
          </p>
        </div>
      </section>

      <section className="vision-mission">
        <div className="hex vision">
          <h2>VISION</h2>
          <p>At the Quantum Computing Club SRMIST, we believe that quantum computing is the future, and we’re here to be at the forefront of this revolution.</p>
        </div>
        <div className="hex mission">
          <h2>MISSION</h2>
          <p>Our mission is to demystify this complex field, empower students to explore its potential, and create an ecosystem where creativity meets cutting-edge technology.</p>
        </div>
      </section>

      <section ref={newsletterRef} className="newsletter">
        <h2>Newsletters</h2>
        <div 
          className="newsletter-card" 
          onMouseEnter={() => setIsHovered(true)} 
          onMouseLeave={() => setIsHovered(false)}
        >
          <div 
            className="newsletter-slider" 
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {newsletters.map((newsletter, index) => (
              <div key={index} className="newsletter-item">
                <div className="newsletter-content">
                  <img src={newsletter.image} alt={`Newsletter ${index + 1}`} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;

