import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeNavbar = () => {
    setIsOpen(false); // Close the menu when a link is clicked
  };

  return (
    <nav className="navbar">
      <div className="hamburger" onClick={toggleNavbar}>
        {/* Hamburger icon */}
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
      <ul className={`navbar-links ${isOpen ? "open" : ""}`}>
        <li><Link to="/" onClick={closeNavbar}>Home</Link></li>
        <li><Link to="/Team" onClick={closeNavbar}>Team</Link></li>
        <li><Link to="/Event" onClick={closeNavbar}>Events</Link></li>
        <li><Link to="/Achievements" onClick={closeNavbar}>Achievements</Link></li>
        <li><Link to="/Gallery" onClick={closeNavbar}>Gallery</Link></li>
        <li><Link to="/Contact" onClick={closeNavbar}>Contact Us</Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;